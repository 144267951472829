import jQuery from 'jquery';
import barba from '@barba/core';
import { gsap } from 'gsap';

function parallaxImages() {
  jQuery($ => {
    const $parallaxImages = $('[data-parallax-images]');
    if (!$parallaxImages.length) return;

    const $parallaxImage = $('[data-parallax-image]');

    // Handle size of image
    let $parentWidth = $parallaxImages.innerWidth();
    let $parentHeight = $parallaxImages.innerHeight();
    $parallaxImage.find('img').width($parentWidth);
    $parallaxImage.find('img').height($parentHeight);
    $(window).on('resize', function() {
      $parentWidth = $parallaxImages.innerWidth();
      $parentHeight = $parallaxImages.innerHeight();
      $parallaxImage.find('img').width($parentWidth);
      $parallaxImage.find('img').height($parentHeight);
    });

    const PARALLAX_SPEED = 1.2;

    gsap.utils.toArray($parallaxImage).forEach((image) => {
      
      gsap.set(image, {
        scale: PARALLAX_SPEED,
      });

      gsap.fromTo(image, {
        y: () => {
          return `-${Math.floor(($parentHeight * PARALLAX_SPEED) - $parentHeight)}px`;
        },
      }, {
        y: () => {
          return `${Math.floor(($parentHeight * PARALLAX_SPEED) - $parentHeight)}px`;
        },
        ease: 'none',
        scrollTrigger: {
          trigger: $parallaxImages,
          scrub: true,
          start: 'top bottom',
          end: 'bottom top',
          invalidateOnRefresh: true, // to make it responsive
        }
      });

    });

  });
}

// Initialise
parallaxImages();

barba.hooks.afterEnter(() => {
  parallaxImages();
});