import jQuery from 'jquery';
import 'slick-carousel';
import barba from '@barba/core';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';

const initializeCarousel = () => {
  jQuery(($) => {
    $(document).ready(function() {
      $('.slider').removeClass('hide');
      $('.carousel-container').removeClass('without-slider');
      $('.slider').not('.slick-initialized').slick({
        infinite: true,
        slidesToShow: 1,
        dots: false,
        arrows: true,
        // responsive: [
        //   {
        //     breakpoint: 992,
        //     settings: {
        //       arrows: false,
        //     },
        //   },
        // ],
      });

      // setDotsPosition();
      // $(window).resize(setDotsPosition);
    });
    ScrollTrigger.refresh();
  });
};

const setDotsPosition = () => {
  jQuery(($) => {
    const currentSlide = $('.slick-current');
    const currentDescrBlok = currentSlide.find('.slider-item__content');
    const currentDescrParagraph = currentDescrBlok.find('p').first();
    const paragraphOffset = currentDescrParagraph.offset();

    const sliderDots = $('.slick-dots');
    const position = $(window).width() <= '991.98' ? { left: 'inherit' } : { left: paragraphOffset.left };
    sliderDots.offset(position);
  });
};

initializeCarousel();
barba.hooks.afterEnter(() => {
  initializeCarousel();
});
