import { computePageCount } from './util';

const DATA = process.env.news.news_section;

export const PAGINATION_PAGE_PER_VIEW = DATA.page_per_view;
export const NEWS_PER_PAGE = DATA.news_per_page;
export const NEWS_ARR = [...DATA.news].sort((a,b) => a.news_order - b.news_order);
export const FEATURED_NEW = DATA.featured_news;

//fist page could contain big featured-new card that equals 6 plain news-card according to design
//Also first 2 plain news card should be bigger that other (they equals 3 plain news card according to design)
export const NEWS_PER_FIRST_PAGE = FEATURED_NEW ? NEWS_PER_PAGE - 6 - 1 : NEWS_PER_PAGE -1;

export const PAGE_COUNT = computePageCount(NEWS_ARR, NEWS_PER_PAGE, NEWS_PER_FIRST_PAGE);