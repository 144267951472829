
export const computePageCount = (newsArr, newsPerPage, newsPerFirstPage) => {
  if (newsArr.length <= newsPerFirstPage) {
    return 1;
  }
  
  const pageCount = 1;
  const slicedNewsArr = newsArr.slice(newsPerFirstPage);
  const slicedPageCount = Math.ceil(slicedNewsArr.length / newsPerPage);
  
  return pageCount + slicedPageCount;
};

export const getCurrentPageFromQuery = () => {
  return new URL(window.location.href).searchParams.get('page') || 1;
};