var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<article class=\"post\">\n  <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"news_link") || (depth0 != null ? lookupProperty(depth0,"news_link") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"news_link","hash":{},"data":data,"loc":{"start":{"line":2,"column":11},"end":{"line":2,"column":26}}}) : helper)))
    + "\" class=\"post__link\" rel=\"bookmark\" target=\"_blank\" rel=\"noopener noreferrer\">\n    <div class=\"post__content\">\n      <h2 class=\"post__title mb-0\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"news_title") || (depth0 != null ? lookupProperty(depth0,"news_title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"news_title","hash":{},"data":data,"loc":{"start":{"line":4,"column":35},"end":{"line":4,"column":51}}}) : helper)))
    + "</h2>\n    </div>\n    <div class=\"post__thumbnail\">\n      <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"news_image") || (depth0 != null ? lookupProperty(depth0,"news_image") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"news_image","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":32}}}) : helper)))
    + "\" loading=\"lazy\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"news_title") || (depth0 != null ? lookupProperty(depth0,"news_title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"news_title","hash":{},"data":data,"loc":{"start":{"line":7,"column":54},"end":{"line":7,"column":70}}}) : helper)))
    + "\">\n    </div>\n  </a>\n</article>";
},"useData":true});