import barba from '@barba/core';
import mapStyles from './config/maps-styles.js';

/* global google */
let map;

function initMap() {
  const elodieCoordinates = { lat: 33.994628, lng: -118.474849 };
  map = new google.maps.Map(document.createElement('div') , {
    center: elodieCoordinates,
    zoom: 15,
    mapTypeControl: false,
    streetViewControl: false,
    styles: [...mapStyles]
  });

  const infowindow = new google.maps.InfoWindow({
    content:
      '<div><h5>Elodie Games</h5><div><b>Address</b> : 614 Hampton Drive, Venice, CA 90291 </div></div>'
  });

  const marker = new google.maps.Marker({
    position: elodieCoordinates,
    map: map,
    label: 'E',
    title: 'Elodie Games'
  });

  google.maps.event.addListener(marker, 'click', function() {
    infowindow.open(map, marker);
  });

  pasteMap(map);
} 

window.initMap = initMap;

function pasteMap(map) {
  const mapWrapper =  document.getElementById('map');
  if (!mapWrapper) return;
  mapWrapper.appendChild(map.getDiv());
}

barba.hooks.after(() => {
  pasteMap(map);
});