import barba from '@barba/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';

// On scroll animation elements
let sectionTriggerOffsetTop = 'top 75%';

const scrollSectionAnim = () => {
  const dataSectionAnim = document.querySelector('[data-section-anim]');

  if (!dataSectionAnim) return;

  gsap.utils.toArray('[data-section-anim]').forEach((element) => {
    const animEl = element.querySelectorAll('[data-anim]'); // Element to animate

    animEl.forEach((element) => {
      sectionTriggerOffsetTop = (element.classList.contains('job__link')) ? 'top 90%' : 'top 75%';
    });

    // Starting position of animation
    gsap.set(animEl, {
      autoAlpha: 0,
      skewX: 0.5,
      x: -16,
    });

    // The animation that triggers when scrolled to the data-section-anim
    const animate = () => {
      const timeline = new gsap.timeline();
      timeline
        .to(animEl, {
          autoAlpha: 1,
          skewX: 0,
          x: 0,
          ease: 'power3.out',
          duration: 1.25,
          stagger: 0.2,
        });
    };
    
    ScrollTrigger.create({
      trigger: element,
      start: sectionTriggerOffsetTop,
      onEnter: () => animate(),
    });

  });
};

// Initialise
scrollSectionAnim();
barba.hooks.after(() => {
  scrollSectionAnim();
});