import jQuery from 'jquery';
import barba from '@barba/core';
import Headroom from 'headroom.js';

const mainMenuToggles = () => {
  jQuery($ => {
    const $mainMenu = $('#main-menu');

    // Close the menu if window width is resized past breakpoint
    // Minor responsive improvement to avoid cross-breakpoint layout issues
    const BREAKPOINT_MD = 768;
    $(window).on('resize', function() {
      if ($(window).width() > BREAKPOINT_MD) {
        if($mainMenu.hasClass('show')) {
          $mainMenu.collapse('hide');
        }
      }
    });

    // Show/hide header overlay on mobile menu open/close
    const $body = $('body');
    const bodyMenuOpenClass = 'menu-is-open';
    const $headerOverlay = $('#header-overlay');
    const headerOverlayOpenClass = 'is-open';
    
    $mainMenu
      .on('show.bs.collapse', function() {
        $headerOverlay.addClass(headerOverlayOpenClass);
        $body.addClass(bodyMenuOpenClass);
        $headerOverlay.on('click', function() {
          $mainMenu.collapse('hide');
        });
      })
      .on('hide.bs.collapse', function() {
        $headerOverlay.removeClass(headerOverlayOpenClass);
        $body.removeClass(bodyMenuOpenClass);
      });

    barba.hooks.beforeLeave(() => {
      $body.removeClass(bodyMenuOpenClass);
    });
    
  });
};


// Headroom setup
const headroomInit = () => {
  const header = document.getElementById('header');
  const headroomOptions = {
    offset : 24,
    tolerance : 4,
    classes : {
      initial : 'header',
      pinned : 'header--pinned',
      unpinned : 'header--unpinned',
      top : 'header--top',
      notTop : 'header--not-top',
      bottom : 'header--bottom',
      notBottom : 'header--not-bottom',
      frozen: 'header--frozen'
    },
  };
  const headroom  = new Headroom(header, headroomOptions);
  headroom.init();
};

const scaleHeader = () => {
  jQuery($ => {
    $(document).ready(() => {
      $(window).on('scroll', () => {
        const scroll = $(window).scrollTop();
        if (scroll > 0 && $(document).width() >= '768') {
          $('.header__brand').addClass('scrolled');
          $('.brand').addClass('scrolled-img');
          $('.header__nav').addClass('scrolled');
        } else {
          $('.header__brand').removeClass('scrolled');
          $('.brand').removeClass('scrolled-img');
          $('.header__nav').removeClass('scrolled');
        }
      });
    
    });
  });
};


// Initialise functions
mainMenuToggles();
headroomInit();
scaleHeader();

barba.hooks.after(() => {
  mainMenuToggles();
  headroomInit();
  scaleHeader();
});