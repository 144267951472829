module.exports = function (pagesCount, currentPage, btnsPerView, block) {
  let accum = '';
  let prevPagesCount = 1;

  if (btnsPerView % 2 != 0) {
    prevPagesCount = Math.floor(btnsPerView / 2);
  }

  if (btnsPerView % 2 === 0) {
    prevPagesCount = (btnsPerView / 2) - 1;
  }

  if (pagesCount <= btnsPerView) {
    for (let i = 1; i <= pagesCount; ++i) {
      block.data.index = i;
      block.data.first = i === 1;
      block.data.last = i === pagesCount;
      accum += block.fn(this);
    }

    return accum;
  }

  let prevPageIndex =
    currentPage === 1 || currentPage === prevPagesCount
      ? 1
      : currentPage === pagesCount
        ? Math.max(currentPage - btnsPerView + 1, 1)
        : Math.max(currentPage - prevPagesCount, 1);

  const lastPageIndex =
    currentPage === 1 || currentPage === prevPagesCount
      ? Math.min(1 + (btnsPerView - 1), pagesCount)
      : Math.min(currentPage + (btnsPerView - prevPagesCount - 1), pagesCount);

  const computedBtnsPerView = lastPageIndex - prevPageIndex + 1;
  if (computedBtnsPerView < btnsPerView) {
    prevPageIndex = prevPageIndex - (btnsPerView - computedBtnsPerView);
  }
  for (let i = prevPageIndex; i <= lastPageIndex; ++i) {
    block.data.index = i;
    block.data.first = i === 1;
    block.data.last = i === pagesCount;
    accum += block.fn(this);
  }
  return accum;
};
