export default {
  createCookie,
  getCookie,
  delay,
};

function createCookie(name, value, hours) {
  let expires;

  if (hours) {
    let date = new Date();
    date.setTime(date.getTime()+(hours*60*60*1000));
    expires = '; expires='+date.toGMTString();
  } else {
    expires = '';
  }

  document.cookie = name+'='+value+expires+'; path=/';
}

function getCookie(name) {
  let value = '; ' + document.cookie;
  let parts = value.split('; ' + name + '=');
  if (parts.length == 2) return parts.pop().split(';').shift();
}


function delay(n) {
  n = n || 2000;
  return new Promise(done => {
    setTimeout(() => {
      done();
    }, n);
  });
}