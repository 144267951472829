import jQuery from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';

// gsap config
gsap.registerPlugin(ScrollTrigger);

gsap.config({
  nullTargetWarn: false,
});

jQuery($ => {
  $(window).on('resize', function() {
    ScrollTrigger.refresh();
  });
});