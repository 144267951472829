import barba from '@barba/core';
import paginationTemplate from '../../partials/pagination.hbs';
import newsTemplate from '../../partials/news-post-container.hbs';
import { getCurrentPageFromQuery } from './util';
import { NEWS_ARR, NEWS_PER_FIRST_PAGE, NEWS_PER_PAGE, PAGE_COUNT, PAGINATION_PAGE_PER_VIEW } from './const';

const renderPagination = () => {
  const paginationContainer = document.getElementById('news-pagination');
  if (!paginationContainer) return;

  if (PAGE_COUNT === 1 ) {
    paginationContainer.classList.add('d-none');
    return;
  }

  const currentPage = +getCurrentPageFromQuery();

  const paginationElem = paginationTemplate({
    pageCount: PAGE_COUNT,
    currentPage,
    pageSize: PAGINATION_PAGE_PER_VIEW,
  });
  paginationContainer.innerHTML = paginationElem;
};

const renderNews = () => {
  const newsContainer = document.getElementById('news');
  if (!newsContainer) return;

  const currentPage = +getCurrentPageFromQuery();
  const offset =
    currentPage === 1
      ? 0
      : currentPage === 2
        ? NEWS_PER_FIRST_PAGE
        : NEWS_PER_FIRST_PAGE + NEWS_PER_PAGE * (currentPage - 2);

  const newsPerPage = currentPage === 1 ? NEWS_PER_FIRST_PAGE : NEWS_PER_PAGE;

  const newsElem = newsTemplate({
    news: NEWS_ARR.slice(offset, newsPerPage + offset),
  });

  newsContainer.innerHTML = newsElem;
};

const renderFeaturedNews =  () => {
  const featuredNew = document.getElementById('featured-new');
  if (!featuredNew) return;

  const currentPage = +getCurrentPageFromQuery();
  if (currentPage === 1) {
    featuredNew.classList.remove('d-none');
    return;
  }

  featuredNew.classList.add('d-none');
};

const firstPageArticleCols =  () => {
  const newsRow = document.getElementById('news');
  if (!newsRow) return;

  const currentPage = +getCurrentPageFromQuery();
  if (currentPage === 1) {
    newsRow.classList.add('is-first-page');
    return;
  }

  newsRow.classList.remove('is-first-page');
};


const scrollPageToTop = () => {
  const buttons = document.querySelectorAll('.btn-pagination');
  buttons.forEach(btn => {
    btn.addEventListener('click', () => {
      window.scrollTo(0, 0);
    });
  });
};

// initialization
renderPagination();
renderFeaturedNews();
firstPageArticleCols();
renderNews();
scrollPageToTop();

barba.hooks.after(() => {
  renderPagination();
  renderFeaturedNews();
  firstPageArticleCols();
  renderNews();
  scrollPageToTop();
});
