import jQuery from 'jquery';
import barba from '@barba/core';
import CommonUtil from './util/common-util.js';

function cookieConsent() {

  jQuery($ => {
    const $cookieConsent = $('.cookie-consent');
    const $cookieBtnAccept = $('.js-cookies-accept');
    const $cookieBtnReject = $('.js-cookies-reject');

    function executeTrackingScripts() {
      let first_src = $('script[data-on-tracking=first]').attr('src');
      $.getScript(first_src, function() {
        let second_code = $('script[data-on-tracking=second]').text();
        second_code += '; window.gtag=gtag;';
        eval(second_code);
      });
    }

    // Check for cookies
    if (!CommonUtil.getCookie('cookies-banner')) {
      $cookieConsent.removeClass('d-none');
      $cookieConsent.addClass('is-visible');
    } else {
      if (CommonUtil.getCookie('cookies-banner') === 'accepted') {
        executeTrackingScripts();
      }
    }

    // Click handlers
    $cookieBtnAccept.on('click', function() {
      CommonUtil.createCookie('cookies-banner', 'accepted', 8760);
      executeTrackingScripts();
      $cookieConsent.hide();
    });

    $cookieBtnReject.on('click', function() {
      $cookieConsent.hide();
      CommonUtil.createCookie('cookies-banner', 'denied');
    });
  });

}

// Initialise
cookieConsent();

barba.hooks.after(() => {
  cookieConsent();
  // Send page view on barba page transition
  if (typeof gtag !== 'undefined') {
    gtag('event', 'page_view', {
      page_title: document.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }
});