import CommonUtil from './util/common-util';
import barba from '@barba/core';
import { gsap } from 'gsap';
import lottie from 'lottie-web';
import logoWordmarkAnimData from './config/logo-wordmark-animation-data.js';

const html = document.querySelector('html');
const loader = document.getElementById('loader');
const loaderLogo = document.getElementById('loader-logo');

const resetLoaderState = () => {
  gsap.set(loader, {
    visibility: 'hidden',
    clipPath: 'polygon(0 100%, 100% 100%, 100% 100%, 0% 100%)',
  });
};

resetLoaderState();

function scrollBackToTop() {
  gsap.delayedCall(0.5, function () {
    window.scrollTo('top', { duration: 0 });
  });
}

const logoAnim = lottie.loadAnimation({
  container: loaderLogo,
  renderer: 'svg',
  loop: false,
  autoplay: false,
  animationData: logoWordmarkAnimData,
});

logoAnim.setSpeed(1.1);

const loadIn = () => {
  html.classList.add('is-leaving');
  gsap.globalTimeline.clear();
  gsap.set(loader, { visibility: 'visible' });
  gsap.to(loader, {
    clipPath: 'polygon(0 0%, 100% 0%, 100% 100%, 0% 100%)',
    duration: 0.6,
    ease: 'power3.inOut',
    onComplete: () => {
      scrollBackToTop();
    },
  });
  gsap.delayedCall(0.1, function () {
    logoAnim.play();
  });
};

const loadOut = () => {
  setTimeout(() => {
    html.classList.remove('is-leaving');
  }, 500);
  gsap.to(loader, {
    clipPath: 'polygon(0 0, 100% 0, 100% 0%, 0 0%)',
    duration: 0.5,
    ease: 'power3.inOut',
    onComplete: () => {
      logoAnim.stop();
      resetLoaderState();
      gsap.set(loader, { visibility: 'hidden' });
    },
  });
};

barba.init({
  sync: true,
  transitions: [
    {
      name: 'default',
      from: {
        custom: ({current, next}) => {
          const currentURL = new URL(current.url.href);
          const nextURL = new URL(next.url.href);
          
          if (currentURL.pathname !== nextURL.pathname) {
            return true;
          }

          const currentURLSearchParams = currentURL.searchParams;
          const nextURLSearchParams = nextURL.searchParams;

          currentURLSearchParams.delete('page');
          nextURLSearchParams.delete('page');

          return currentURLSearchParams.toString() !== nextURLSearchParams.toString();
        },
      },
      async leave() {
        const done = this.async();
        loadIn();
        await CommonUtil.delay(1400);
        done();
      },
      async enter() {
        loadOut();
      },
    },
    {
      name: 'self'
    },
  ],
});


// Initial loading animation when first loading onto the site
async function initialLoadAnimation() {
  html.classList.add('is-leaving');
  gsap.set(loader, {
    visibility: 'visible',
    clipPath: 'polygon(0 0%, 100% 0%, 100% 100%, 0% 100%)',
  });
  await setTimeout(() => {
    logoAnim.play();
  }, 100);
}

initialLoadAnimation()
  .then(() => {
    setTimeout(() => {
      loadOut();
    }, 1400);
  });