import './scripts/gsap-config';
import './scripts/page-transitions';
import 'bootstrap/js/dist/collapse';
import './scripts/cookie-consent';
import './scripts/header';
import './scripts/scroll-animations';
import './scripts/google-map';
import './scripts/carousel-component';
import './scripts/parallax-images';
import './scripts/news-pagination';
import './scripts/post-title-height';
import './scripts/footer-animation';