import barba from '@barba/core';

const calculationLineHeight = () => {
  const postTitles = document.querySelectorAll('.post__title');
  postTitles.forEach((title) => {
    const lineHeight = window.getComputedStyle(title).lineHeight.replace('px', '');
    title.style.height = Number(lineHeight) * 5 + 'px'; //line-clamp(5) from _news.scss
  });
};

const setLineHeight = () => {
  calculationLineHeight();
  window.addEventListener('resize', calculationLineHeight);
};

// initialization
setLineHeight();

barba.hooks.after(() => {
  setLineHeight();
});
